import React, { Component } from "react";
import defaultImg from "../images/dProfile.jpg";
import defaultImgGl from "../images/gl.jpg";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import GenericHeader from "../../../utils/GenericHeader";
import { styles } from "../../../utils/styles/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as PendingActivityActions from "../Approvals/PendingActivitySubComponents/PendingActivityActions";
import Axios from "axios";
import { BASE } from "../../../API/APIendpoint";
import Radio from "@material-ui/core/Radio";
import FadeLoader from "react-spinners/FadeLoader";
import { DASHBOARD_ACTIONS } from "../../../store/actions/actionTypes";

class PendingActivityApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _allPendingActivities: true,
      _newPendingActivitiesForTheMonth: false,
      _currentMonth: new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`,
      members:[], // member per activity
      loading: true
    }
    if (!this.props.loggedIn) {
      this.props.history.push("/");
    }
  }
  componentDidMount() {
    let _activities = this.props.activities?.filter(act => act.status =='Pending').slice(0, 20);
    this.props.onStatusAChange(_activities); //update activities on the store
    if(_activities)
    {
      _activities.forEach(act => {
        Axios({
          url: BASE + `api/Member/FindMemberByMemNo/${act.memNo}`,
          method: "get",
          headers: {
            "Content-type": "application/json",
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
          }
        }).then(res => {
              this.setState({members : [...this.state.members,res.data] });
          })
          
        this.setState({ loading: false});
  
      });
    }
    else{
      this.setState({ loading: false});
    }

  }

  handleSubmit = (member, activity) => {
    this.props.PendingActivityActions.setMemberActivity(member, activity);
    this.props.history.push("/admin/ApprovePendingActivities");
  };

  //TODO: will come back to the sorting
  sortByFirstName = array => {
    return array.sort((a, b) =>
      a.firstname > b.firstname ? 1 : b.firstname > a.firstname ? -1 : 0
    );
  };

  render() {
    const pendingFilteredDS = this.props.activities;
    return (
      <div className="container" style={{ marginTop: "60px" }}>
        <div className="mb-4  mt-5">
          <GenericHeader
            title="Activities"
            showBackButton
            backFunction={() => this.props.history.push("/MainAdmin")}
          />
        </div>
        {pendingFilteredDS.length === 0 ? (
          <div className="text-warning font-weight-bold">There are currently no pending activities...</div>
        ) : (

          this.state.loading ?
              <div className="row" style={{ marginTop: "80px" }}>
                <div className="col-12 d-flex justify-content-center">
                  <FadeLoader color="orange" margin="auto" />
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <p className="font-weight-bold">
                    Loading the activities. Please wait ...
                  </p>
                </div>
              </div>
          :
             <ul className="ml-0 pl-1">
              {pendingFilteredDS.map((activity, index) => {
                const member = this.state.members.filter(mem => activity.memNo == mem.memNo)[0];
                if (!member) {
                  return null;
                }
                
                return (
                  <div
                    key={index}
                    className="row h-4 mb-2 border-bottom pb-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleSubmit(member, activity)}
                  >
                    <div className="row pl-2">
                      
                      <div className="col-0 ml-3" >
                        <div className="h6 mb-0 pr-2">
                          {member
                            ? `${member.firstname.toString().substring(0, 1)}`
                            : null}
                        </div>
                        <img src={member.sex === "Male" ? defaultImg : defaultImgGl} alt="Gun" className="avatar" style={{ width: "100px", height: "100px" }} />
                      </div>

                      <div className="col-8 pl-3 pt-3 " align="left">
                        <div className="h6">
                          {member ? `${member.firstname} ${member.surname}` : null}
                        </div>
                        <div className="h6">
                          {member ? `${member.memNo}` : null}
                        </div>
                        <div className="h6">
                          {activity ? `Activity Date: ${activity.activityDate.substring(0, 10)}` : null}
                        </div>
                        <div className="h6">
                          {activity ? `${activity.activityDescription}` : null}
                        </div>
                        <div className="text-warning">
                          Pending Activity Approval
                      </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </ul>
          )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    activities: state.dashBoardReducer.activities.activities,
    // memberList: state.dashBoardReducer.totalMembers.members,
    loggedIn: state.loginReducer.userProfileDetails.isLoggedIn
  };
};

const bindActionsToProps = dispatch => {
  return {
    PendingActivityActions: bindActionCreators(PendingActivityActions, dispatch),

    onStatusAChange: (activities) =>
    dispatch({
      type: DASHBOARD_ACTIONS.UPDATE_ACTIVITY_OBJECT,
      activities: {
        activities: activities
      }
    })
  };
};
export default connect(
  mapStateToProps,
  bindActionsToProps
)(withStyles(styles)(withRouter(PendingActivityApplication)));
